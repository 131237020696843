import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next'
import { Select } from "antd";

const Option = Select.Option

const RegistrationChart = ({ data }) => {
  const { t, i18n } = useTranslation()
  const [selectedDay, setSelectedDay] = useState(data?.[0] || null);

  const categories = Array.from({ length: 24 }, (_, i) => `${i}:00`);

  const seriesData = selectedDay && selectedDay.hours.length > 0 ? [
    {
      name: `${selectedDay.year}-${String(selectedDay.month).padStart(2, "0")}-${String(
        selectedDay.day
      ).padStart(2, "0")}`,
      data: selectedDay.hours.map((hour) => hour.count),
    },
  ] : [
    {
      name: t("menu.registrationStat.noData"),
      data: Array(24).fill(0),
    },
  ]
  console.log(selectedDay,'seriesData');
  

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
    },
    title: {
      text: t("menu.registrationStat.hour"),
      align: "center",
      style: { fontSize: "18px", fontWeight: "bold", color: "#333" },
    },
    xaxis: {
      categories,
      labels: { style: { fontSize: "12px" } },
    },
    yaxis: {
      labels: {
        formatter: (val) => val.toFixed(0),
        style: { fontSize: "12px" },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
      y: {
        formatter: (val) => `${val} ${t("menu.registrationStat.registrations")}`,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 4,
    },
    fill: {
      colors: ["#2e5abf"],
      opacity: 0.9,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#2e5abf"],
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div>
      <label htmlFor="dayFilter" style={{ marginRight: "10px" }}>
        {t('menu.registrationStat.select')}
      </label>
      <Select
        id="dayFilter"
        value={selectedDay
          ? `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
          : undefined}
        onChange={(e) => {
          const [year, month, day] = e.split("-");
          const filteredDay = data.find(
            (d) =>
              d.year === parseInt(year) &&
              d.month === parseInt(month) &&
              d.day === parseInt(day)
          );
          setSelectedDay(filteredDay || null);
        }}
      >
        {data.map((day) => (
          <Option
            key={`${day.year}-${day.month}-${day.day}`}
            value={`${day.year}-${day.month}-${day.day}`}
          >
            {`${day.year}-${String(day.month).padStart(2, "0")}-${String(
              day.day
            ).padStart(2, "0")}`}
          </Option>
        ))}
      </Select>

      <Chart options={chartOptions} series={seriesData} type="bar" height={350} />
    </div>
  );
};




export default RegistrationChart